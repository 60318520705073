import React from "react";
import { Link } from "react-router-dom";
import "./Header.css";

const Header = () => {
  return (
    <nav className="text-white bg-stone-950 shadow-lg p-2">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16 w-full">
          <div className="flex items-center">
            <h1 className="text-3xl font-bold mr-10">
              <a href="/">
              <img src="logo.png" className="w-48 py-2 px-4" alt="Logo" />
              </a>
            </h1>
          </div>
          <div className="hidden md:flex md:items-center md:space-x-8">
            {/*<Link
              to="/rightsmapedal"
              className="text-xl bg-stone-950 hover:bg-stone-900 px-4 py-2 rounded-md"
            >
              RightsmaPedal
            </Link>
            <Link
              to="/serial-communication"
              className="text-xl bg-stone-950 hover:bg-stone-900 px-4 py-2 rounded-md"
            >
              Configuration Tool
            </Link> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Header;
