import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import RightsmaPedal from "./components/RightsmaPedal";
import AboutUs from "./components/AboutUs";
import SerialCommunication from "./components/SerialCommunication";
import { Analytics } from "@vercel/analytics/react";

const App = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/rightsmapedal" element={<RightsmaPedal />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route
            path="/serial-communication"
            element={<SerialCommunication />}
          />
        </Routes>
      </Router>
      <Analytics />
    </>
  );
};

export default App;
