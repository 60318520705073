import React from "react";

const Footer = () => {
  return (
    <footer className="text-gray-400 text-center py-8">
      <div className="max-w-7xl mx-auto px-4 text-xs">
        Rightsma Systems Limited. © 2023
      </div>
    </footer>
  );
};

export default Footer;
