import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer from "./Footer";
import "./font.css";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// arrow up down left right
import { faArrowsUpDownLeftRight } from "@fortawesome/free-solid-svg-icons";
import { SparklesPreview } from "./SparklesPreview";
import { ContactUs } from "./ThreeDCardDemo.tsx";
import FeatureList from "./FeatureList";
import { ReactImageTurntable } from "react-image-turntable";

const Home = () => {
  const [width, setWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const images = Array.from(
    { length: 36 },
    (_, i) => `pedal_frames/frame.${i}.png`
  );

  return (
    <div className="bg-stone-950 flex-col justify-center">
      <Header />
      {/* Add dark:bg-gray-900 for dark background and text-white for light text */}
      <div className="h-screen flex-col justify-center items-center mt-12 space-x-4 text-white">
        <div className="mt-16 mx-24 px-24 grid gap-8 sm:grid-col md:grid-cols-2 flex justify-center align-center">
          <div className="flex justify-center align-center">
            <div className="grid-col w-full flex my-auto">
              {/* Keep text-gray-800 for light theme or use text-white for dark theme */}
              <div>
                <p className="py-8 text-6xl">
                  Take your sim racing to the <b>next level</b>.
                </p>
                <a href="https://buy.stripe.com/28oeX528W6JQ9bybIO">
                  <a
                    href="https://discord.gg/HPT2PXUXnA"
                    className="mx-auto mt-4"
                  >
                    <button
                      translateZ={20}
                      class="shadow-lg shadow-red-500/50 font-semibold text-lg group relative inline-flex h-12 items-center justify-center rounded-md bg-black border-2 border-gray-400 hover:bg-stone-900 px-6 py-6 text-white"
                    >
                      <p>Join our Discord</p>
                      <img
                        src="discord.png"
                        className="w-8 ml-4"
                        alt="Discord"
                      />
                    </button>
                  </a>
                </a>
              </div>
            </div>
          </div>
          <div className="flex justify-center align-center">
            <div className="w-full">
              {/* Ensure the image is visible and looks good on a dark background */}
              <img src="pedal.png" className="mx-auto w-11/12" alt="Pedal" />
            </div>
          </div>
        </div>
        <div className="flex-col justify-center align-center text-white pt-8">
          <p className="text-center text-md text-gray-400">
            Trusted by racers competing in
          </p>
          <div className="p-2">
            <img
              src="porsche-tag-heuer.png"
              className="mx-auto h-32"
              alt="logo"
            />
          </div>
        </div>
      </div>
      {width < 768 ? (
        // mobile
        <div className="py-24"></div>
      ) : (
        // desktop
        <div>
          <SparklesPreview text="A pedal for performance. ⚡️" />
        </div>
      )}
      <div className="flex justify-center align-center text-white sm:h-full md:h-screen lg:h-screen w-full">
        {/* Product 3D view carosel */}
        <div className="w-2/3 mx-auto">
          <ReactImageTurntable
            images={images}
            autoRotate={{ disabled: true, interval: 0 }}
          />
          <p className="flex justify-center text-center text-gray-500">
            ↩️ Drag to rotate
          </p>
        </div>
      </div>
      <FeatureList />
      <div className="grid grid-col w-full flex justify-center align-center">
        <a href="https://buy.stripe.com/28oeX528W6JQ9bybIO">
          <button class="w-64 shadow-lg shadow-red-500/50 font-semibold text-2xl group relative inline-flex h-20 items-center justify-center rounded-md bg-black border-2 border-gray-400 hover:bg-stone-900 px-6 py-6 text-white">
            <span>Pre-order Now</span>
            <div class="relative ml-1 h-5 w-5 overflow-hidden">
              <div class="absolute transition-all duration-200 group-hover:-translate-y-5 group-hover:translate-x-4">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5"
                >
                  <path
                    d="M3.64645 11.3536C3.45118 11.1583 3.45118 10.8417 3.64645 10.6465L10.2929 4L6 4C5.72386 4 5.5 3.77614 5.5 3.5C5.5 3.22386 5.72386 3 6 3L11.5 3C11.6326 3 11.7598 3.05268 11.8536 3.14645C11.9473 3.24022 12 3.36739 12 3.5L12 9.00001C12 9.27615 11.7761 9.50001 11.5 9.50001C11.2239 9.50001 11 9.27615 11 9.00001V4.70711L4.35355 11.3536C4.15829 11.5488 3.84171 11.5488 3.64645 11.3536Z"
                    fill="currentColor"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 -translate-x-4"
                >
                  <path
                    d="M3.64645 11.3536C3.45118 11.1583 3.45118 10.8417 3.64645 10.6465L10.2929 4L6 4C5.72386 4 5.5 3.77614 5.5 3.5C5.5 3.22386 5.72386 3 6 3L11.5 3C11.6326 3 11.7598 3.05268 11.8536 3.14645C11.9473 3.24022 12 3.36739 12 3.5L12 9.00001C12 9.27615 11.7761 9.50001 11.5 9.50001C11.2239 9.50001 11 9.27615 11 9.00001V4.70711L4.35355 11.3536C4.15829 11.5488 3.84171 11.5488 3.64645 11.3536Z"
                    fill="currentColor"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
          </button>
        </a>
        <div className="w-64 mt-4 flex justify-center">
          <div className="grid grid-cols-6 items-center justify-center border border-yellow-700 text-yellow-700 py-2 px-4 rounded-lg shadow-lg">
            <div className="col-span-1 text-xl">⚠️</div>
            <div className="col-span-5 text-xs font-semibold">
              Additional 1-month delay in parts due to supply chain
              disruptions.
            </div>
          </div>
        </div>
      </div>
      <img
        src="pedal-side.png"
        className="w-2/3 py-2 px-4 mx-auto"
        alt="Logo"
      />
      {/*<ContactUs />*/}
      <div className="flex flex-col justify-center align-center">
        <img
          src="spencer.png"
          className="w-1/3 py-2 px-4 mx-auto"
          alt="Spencer"
        />
        <p className="text-center text-gray-500 mb-8">
          Founder & CEO, Spencer Reitsma
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
