import React from 'react';
import Header from './Header';
import Footer from './Footer';



const AboutUs = () => {
  return (
    <>
    
      <Header />
      
      <section className="text-center py-10 bg-white">
        <h1 className="text-4xl font-bold text-gray-800 mb-3">About Rightsma Simracing</h1>
        <p className="max-w-2xl mx-auto text-gray-600">Pioneering the future of sim racing technology for competitive enthusiasts around the globe.</p>
      </section>
      <section className="py-10">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-3xl font-bold text-gray-100 mb-4">Our Mission</h2>
          <p className="text-gray-100">At Rightsma Simracing, we're dedicated to pushing the boundaries of sim racing technology, delivering high-performance, competitively priced products for racing enthusiasts.</p>
        </div>
      </section>
      <section className="bg-white py-10">
        <div className="max-w-6xl mx-auto text-right">
          <h3 className="text-3xl font-bold text-gray-800 mb-4">Founder's Story</h3>
          <p className="text-gray-600">Founded in Canada by Spencer Reitsma, Rightsma Simracing began as a vision to revolutionize sim racing experiences. Spencer's passion for racing and technology led to the creation of a brand synonymous with innovation and quality.</p>
        </div>
      </section>
      <section className="py-10">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <h4 className="text-3xl font-bold text-gray-100 mb-4">Our Team and Design Philosophy</h4>
          <p className="text-gray-100">Our products are the result of meticulous design and engineering by our small, skilled team. Every component is crafted in-house, ensuring top-tier quality and performance for our customers.</p>
        </div>
      </section>
      <section className="bg-white py-10">
        <div className="max-w-6xl mx-auto text-right">
          <h5 className="text-3xl font-bold text-gray-800 mb-4">Competitive Edge</h5>
          <p className="text-gray-600">We pride ourselves on offering competitively priced products without compromising on quality, making high-end sim racing accessible to all.</p>
          
        </div>
      </section>
      <section className="bg-gray-100 py-12">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">Our Journey</h2>
          <div className="text-gray-600 mr-24 ml-24">
            <p className="mb-4">
              Founded in Canada by a passionate racer and visionary engineer,
              Spencer Reitsma, Rightsma Simracing has always been at the
              forefront of sim racing technology. From our humble beginnings, we
              have strived to redefine the racing experience for enthusiasts
              around the world.
            </p>
            <br />
            <b>
              <p className="mb-4">
                Our mission is to deliver unparalleled realism and performance
                in racing. We believe in pushing the boundaries of what's
                possible, bringing competitive-grade equipment to racers at
                competitive prices.
              </p>
            </b>
            <br />
            <p>
              Envisioning a future where every sim racer has access to top-tier
              technology, Rightsma Simracing is committed to continuous
              innovation and excellence, ensuring our products not only meet but
              exceed the expectations of the sim racing community.
            </p>
          </div>
        </div>
        <br />
      </section>
      <Footer />
    </>
  );
};


export default AboutUs;