import React, { useState, useEffect } from "react";
import Header from "./Header";
import Footer from "./Footer";
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import "./styles.css";

const SerialCommunication = () => {
  const [port, setPort] = useState(null);
  const [writer, setWriter] = useState(null);
  const [messages, setMessages] = useState([]);
  const [minValue, setMinValue] = useState(25);
  const [maxValue, setMaxValue] = useState(75);
  const [minValue0, setMinValue0] = useState(25);
  const [maxValue0, setMaxValue0] = useState(75);
  const [minValue1, setMinValue1] = useState(25);
  const [maxValue1, setMaxValue1] = useState(75);
  const [minValue2, setMinValue2] = useState(25);
  const [maxValue2, setMaxValue2] = useState(75);
  const [minValue3, setMinValue3] = useState(25);
  const [maxValue3, setMaxValue3] = useState(75);
  const [minValue4, setMinValue4] = useState(25);
  const [maxValue4, setMaxValue4] = useState(75);

  useEffect(() => {
    if (port) {
      const textDecoder = new TextDecoderStream();
      const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
      const reader = textDecoder.readable.getReader();

      const readData = async () => {
        try {
          while (true) {
            const { value, done } = await reader.read();
            if (done) {
              reader.releaseLock();
              break;
            }
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: value, sender: "received" },
            ]);
          }
        } catch (error) {
          console.error("Error reading from serial port:", error);
        }
      };

      readData();

      return () => {
        readableStreamClosed.cancel();
        reader.cancel();
      };
    }
  }, [port]);

  const connectToSerialDevice = async () => {
    try {
      const newPort = await navigator.serial.requestPort();
      await newPort.open({ baudRate: 115200 });
      setPort(newPort);
      const newWriter = newPort.writable.getWriter();
      setWriter(newWriter);
    } catch (error) {
      console.error("There was an error opening the serial port:", error);
    }
  };

  // This function is called whenever the slider values change
  const handleInput_notgay = (event) => {
    // The event.target.value should be an array with the new slider values
    const newValues = event.target.value;
    // Update your state with the new values
    setMinValue(newValues[0]);
    setMaxValue(newValues[1]);
  };

  return (
    <>
      <Header />
      <section className="text-center py-10 bg-white">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-gray-800 mb-6">
            Configuration Tool
          </h2>
          <div className="text-gray-600 mr-24 ml-24">
            <p className="mb-4">
              This tool allows you to configure your Rightsma Pedal. You can
              adjust the pedal's deadzone, maximum range, and more.
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center m-12 space-x-4">
          <a
            href="https://i.pinimg.com/originals/8a/39/03/8a390326148f845c0e26c23d56b7fde9.gif"
            target="_blank"
          >
            <button className="bg-gradient-to-br from-red-600 to-red-800 text-white font-bold py-2 px-4 rounded-lg shadow-xl text-2xl hover:from-red-500 hover:to-red-600 shadow-sm">
              <p className="p-4">Download</p>
            </button>
          </a>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default SerialCommunication;
