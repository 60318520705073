import React from "react";
import { Suspense } from "react";
import Header from "./Header";
import Footer from "./Footer";
import {
  FaRocket,
  FaCheckCircle,
  FaBatteryFull,
  FaRegCompass,
  FaCalculator,
  FaBolt,
} from "react-icons/fa";
import { SiOpentelemetry } from "react-icons/si";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import CustomReactPlayer from "./CustomReactPlayer";
import "./demostyle.css";

function Loading() {
  return <h2>🌀 Loading...</h2>;
}

const RightsmaPedal = () => {
  return (
    <>
      <Suspense fallback={<Loading />}>
        <Header />
        <header className="text-center py-1">
          {" "}
          {/* Further reduced padding */}
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold mt-12 text-black">
              Achieving{" "}
              <p className="bg-gradient-to-r from-red-600 to-red-800 inline-block text-transparent bg-clip-text">
                endless adjustment and telemetry feedback
              </p>{" "}
              at a fraction of the cost.
            </h1>
          </div>
        </header>

        <div className="grid grid-cols-2 gap-1">
          <iframe
            src="/pedal.html"
            className="demoview"
            scrolling="no"
          ></iframe>
          <div>
            {/* Features container */}
            <div className="mt-48">
              {" "}
              {/* Reduced top margin */}
              <div className="bg-white bg-opacity-80 rounded-lg p-3 shadow-xl w-3/4 space-y-2">
                {" "}
                {/* Reduced padding and space between items */}
                <ul className="space-y-2">
                  {" "}
                  {/* Reduced space between list items */}
                  <li className="flex items-center">
                    <SiOpentelemetry className="text-xl text-blue-400 mt-2 mr-4" />{" "}
                    <p className="text-bold text-xl">Telemetry Feedback</p>
                  </li>
                  <li className="flex items-center">
                    <FaRocket className="text-xl text-blue-400 mt-2 mr-4" />
                    <p className="text-bold text-xl">
                      Works With all Major Simracing Titles
                    </p>
                  </li>
                  <li className="flex items-center">
                    <FaCheckCircle className="text-xl text-green-400 mt-2 mr-4" />{" "}
                    <p className="text-bold text-xl">
                      Durable materials and build quality
                    </p>
                  </li>
                  <li className="flex items-center">
                    <FaRegCompass className="text-xl text-red-400 mt-2 mr-4" />{" "}
                    <p className="text-bold text-xl">
                      Full control over your pedal
                    </p>{" "}
                  </li>
                  <li className="flex items-center">
                    <FaBolt className="text-xl text-yellow-400 mt-2 mr-4" />{" "}
                    <p className="text-bold text-xl">
                      Lightning fast inputs from dual-core architecture
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center items-center m-12 space-x-4">
          <a
            href="https://buy.stripe.com/28oeX528W6JQ9bybIO"
            target="_blank"
            rel="noopener noreferrer"
          >
            <button className="bg-gradient-to-br from-red-600 to-red-800 text-white font-bold py-2 px-4 rounded-lg shadow-xl text-2xl hover:from-red-500 hover:to-red-600 shadow-sm">
              <p className="p-4">Pre-order Now</p>
            </button>
          </a>
        </div>
        <Footer />
      </Suspense>
    </>
  );
};

export default RightsmaPedal;
