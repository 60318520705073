// FeatureList.js
import React from "react";

const FeatureList = () => {
  return (
    <div className="text-white py-12">
      <h1 className="text-6xl font-bold text-center text-white relative z-20 p-8">
        Features ⚙️
      </h1>
      <div className="space-y-6 max-w-4xl mx-auto">
        <FeatureItem
          title="Real-Time Telemetry Feedback"
          description="Master your braking, feeling everything available to you from the simulator including ABS, wheel slip, and more, depending on your usage."
          icon="📊"
        />
        <FeatureItem
          title="Universal Game Compatibility"
          description="Seamless integration with all top sim racing titles for a plug-and-play experience across your favorite games utilizing SimHub."
          logo={"simhub-logo.png"}
          icon="🎮"
        />
        <FeatureItem
          title="Built to Last"
          description="Engineered with high-grade plastics and steel for durability that withstands the intensity of racing."
          icon="🔩"
        />
        <FeatureItem
          title="Customizable to Your Style"
          description="Tailor your pedal settings for sensitivity, resistance, and more to perfectly match your racing technique."
          icon="🛠"
        />
        <FeatureItem
          title="Ultra-Responsive Dual-Core Processing"
          description="Experience instant reaction with advanced dual-core architecture, ensuring your inputs are captured faster than ever."
          icon="⚡"
        />
      </div>
    </div>
  );
};

const FeatureItem = ({ title, description, logo, icon }) => {
  return (
    <div className="p-4 gap-8 flex flex-col md:flex-row items-center text-center md:text-left">
      <div className="md:w-1/3">
        <p className="text-xl font-semibold">
          {title} {icon}
        </p>
      </div>
      <div className="md:w-2/3 mt-4 md:mt-0">
        {logo ? (
          <div className="grid grid-cols-3 flex justify-center align-center">
            <p className="col-span-2 text-gray-400">{description}</p>
            <div className="flex justify-center align-center col-span-1 w-full h-full">
            <img src={logo} alt="SimHub Logo" className="h-8" />
                </div>
          </div>
        ) : (
          <div className="grid grid-col">
            <p className="text-gray-400">{description}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default FeatureList;
